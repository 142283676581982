define('gusto/js/app/gusto.chart-module',['window', 'jquery', 'gusto-core', 'tab_system', "lazyload", "song-preview", '!domready'], function (window, $, gusto, tab_system, lazyload, songPreview) {
    "use strict";

    var CHART_API_URL = '/api/1.0/bigtop40_charts/charts_by_year/?year=';

    var chartModule = {
        setupTabs: function() {
            var that = this;
            that.$chartResults = $('.chart-module__charts')
            if (that.$chartResults.data('useTabs') === false) {
                return;
            }

            var tabs = tab_system({
                tab_selector: '.chart-module__chart-instance-group',
                tab_title_selector: 'h3.chart-module__header__tab-title',
                tab_bar_class: 'chart-module__header__tab-bar chart-module__header__tab-bar--entries tab-bar',
                button_label_suffix: '',
                max_tab_number: 4,
                callback_func: function(){
                    songPreview.stop_audio_playback();
                    window.scrollTo(0, that.$chartResults.offset().top);
                }
            });


            tab_system({
                tab_selector: '.chart-module__header__tab-selector',
                tab_bar_class: 'chart-module__header__tab-bar chart-module__header__tab-bar--instances tab-bar',
                button_label_suffix: 'years',
                display_reversed: true,
                max_tab_number: 5,
                remove_content_after_init: true,
                tab_click_override: function(e, $tab) {
                    var tabData = $tab.data()['contentData'];
                    var apiURL = CHART_API_URL + tabData['tab_name'] + '&absolute_url='+tabData['absolute_url'];

                    that.updateUrl(tabData['absolute_url'] + tabData['tab_name'] + '/')

                    that.$chartResults.addClass('chart-module__charts--loading');
                    $.get(apiURL, function(chartData, status){
                        if (status === 'success') {
                            that.loadCharts(chartData);
                        } else {
                            that.loadCharts('<h2 class="chart-module__error-message">There was an issue getting your data, please try again</h2>');
                        }
                    });
                },
                responsive:{
                    level1: {
                        max_tab_number: 3,
                    },
                    level2: {
                        max_tab_number: 5,
                    },
                    level3: {
                        max_tab_number: 7,
                    }
                }
            });

            lazyload.init_for_context(that.$chartResults);

            if (tabs && tabs.length) {
                $('.chart-module__charts').append($(tabs[0].tab_bar).clone(true));
            }
            $('.chart-module__chart-instance-group').removeClass('chart-module__chart-instance-group--loading');
        },
        loadCharts: function(chartData) {
            var that = this;

            that.$chartResults.html(chartData);
            lazyload.init_for_context(that.$chartResults)

            that.$chartResults.removeClass('chart-module__charts--loading');
        },
        updateUrl: function(newUrl){
            history.pushState({ newUrl: newUrl }, newUrl, newUrl);
        }
    };
    chartModule.setupTabs();

    return chartModule;
});

