define('settings',['jquery', 'base_settings', 'devices'], function ($, base_settings, devices) {
    "use strict";

    var settings = $.extend(true, {}, base_settings, {
        always_launch_ukrp: true,
        default_audio_player: true,
    });

    return settings;
});

